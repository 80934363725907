import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { SectionHeader } from '../SectionHeader';
import imgEquipo from '../../../../../images/equipo/equipo.jpg';
import imgEquipoMobile from '../../../../../images/equipo/equipo-mobile.jpg';
import { Divider } from 'src/components/Divider';
import { useText } from 'src/components/TextContext';
import { useMediaQuery } from 'react-responsive';

export const PersonasSection = () => {
  const t = useText();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <>
      <SectionHeader
        css={css`
          margin-top: 5rem;
        `}
      >
        {t.equipo.title}
      </SectionHeader>
      <Divider />
      <div
        css={css`
          display: flex;
          width: 100%;
        `}
      >
        <img
          css={css`
            max-width: min(90em, 100%);
            margin: auto;
          `}
          src={isMobile ? imgEquipoMobile : imgEquipo}
        />
      </div>
    </>
  );
};

export default PersonasSection;
