import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { SectionHeader } from './SectionHeader';
import { SectionDivider } from './SectionDivider';
import { Divider } from 'src/components/Divider'
import { useText } from 'src/components/TextContext';


export const QuienesSomosSection = () => {
  const t = useText();
  const { content } = t.quienesSomos;
  const contentArr = content.split('\n\n');

  return (
    <>
      <SectionHeader className="animate__animated animate__bounce">
        {t?.quienesSomos?.title}
      </SectionHeader>
      <Divider />
      <div
        css={css`
          display: grid;
          margin: auto;
          font-size: 20px;
          @media (min-width: 70rem) {
            max-width: 70rem;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5cm;
          }
          padding: 0 1rem;
        `}
      >
        <div>
          {contentArr.slice(0, contentArr.length / 2).map((x, i) => (
            <Typography
              key={i}
              css={css`
                font-size: 18px;
                white-space: pre-wrap;
                margin: auto;
                text-align: justify;
                margin-bottom: 1rem;
              `}
            >
              {x}
            </Typography>
          ))}
        </div>
        <div>
          {contentArr.slice(contentArr.length / 2).map((x, i) => (
            <Typography
              key={i}
              css={css`
                font-size: 18px;
                white-space: pre-wrap;
                margin: auto;
                text-align: justify;
                margin-bottom: 1rem;
              `}
            >
              {x}
            </Typography>
          ))}
        </div>
      </div>
    </>
  );
};

export default QuienesSomosSection;
