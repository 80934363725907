import React from 'react';
import SEO from 'src/components/seo';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { ContactoSection } from 'src/components/pages/index/ContactoSection';
import { IndexHeader } from 'src/components/pages/index/IndexHeader';
import { HomeScreen } from 'src/components/pages/index/HomeScreen';
import { IndexFooter } from 'src/components/pages/index';
import { QuienesSomosSection } from 'src/components/pages/index/QuienesSomosSection';
import { PersonasSection } from 'src/components/pages/index/PersonasSection';
import { TextContextProvider } from 'src/components/TextContext';

import esTextCommon from '../../text/es/common.yaml';
import esTextIndex from '../../text/es/index.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextIndex from '../../text/en/index.yaml';

const esText = { ...esTextCommon, ...esTextIndex };
const enText = { ...enTextCommon, ...enTextIndex };
const text = {
  es: esText,
  en: enText,
};

const IndexPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text} initialLanguage={'es'}>
        <Layout>
          <SEO title="Home" />
          <IndexHeader location={location} />
          <Main>
            <HomeScreen />
            <QuienesSomosSection />
            <PersonasSection />
            <ContactoSection />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default IndexPage;
