import React from 'react';
import { css } from '@emotion/core';
import backgroundImage from '../../../../images/background/forest.jpg';
import backgroundVideo from '../../../../images/background/background_video.mp4';
import LogoMuticaoWhite from '../../../../images/logo/logo-muticao-01-white.png';

export const HomeScreen = () => {
  return (
    <>
      <video muted loop autoPlay
      
        css={css`
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          max-width: 100%;
          width: 100vw;
          height: 90vh;
          object-fit: cover;
          filter: brightness(0.75);
          clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
          overflow: hidden;
        `}
      >
        <source type='video/mp4' src={backgroundVideo} />
        </video>
      <div
        css={css`
          color: white;
          display: grid;
          max-width: 100%;
          width: 100vw;
          height: 100vh;
          justify-content: center;
          padding-top: 15vh;
        `}
      >
        <img
          src={LogoMuticaoWhite}
          css={css`
            width: 50vw;
            height: 50vh;
            object-fit: scale-down;
          `}
        />
      </div>
    </>
  );
};

export default HomeScreen;
